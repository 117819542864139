<template>
  <!-- 获取id 需要修改 -->
  <!-- v-loading.fullscreen.lock="fullscreenLoading" -->
  <div class="tc-report" element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)">
    <!-- 页面功能 -->
    <div class="tool">
      <ul>
        <li>
          <img src="../../../assets/images/refresh.png" @click="refresh()" alt />
        </li>
        <li>
          <img src="../../../assets/images/retreatBtn.png" @click="back()" alt />
        </li>
      </ul>
    </div>
    <!-- 头部工具 -->
    <div class="tc-report-header" v-if="$route.path.split('/')[1] != 'answer_content'">
      <router-link target="_blank" :to="{ path: '/condownpdf', query: uid }">
        <el-button type="primary" plain>打印报告</el-button>
      </router-link>
      <!-- <el-divider direction="vertical"></el-divider>
      <el-button type="primary" plain @click="showSetReportBox"
        >打印设置</el-button
      > -->
      <!-- <el-divider direction="vertical"></el-divider> -->
      <!-- <el-button type="primary" plain @click="infoEditState" v-if="!editState"
        >编辑内容</el-button
      > -->
      <!-- <el-button type="primary" plain @click="seveEditInfo" v-if="editState"
        >保存</el-button
      > -->
      <!-- <el-divider direction="vertical"></el-divider> -->
      <!-- <el-button type="primary" plain @click="downWord">导出Word</el-button> -->
    </div>
    <!-- 内容主题 -->

    <div v-show="true" id="ccc">
      <div class="tc-report-main">
        <div class="content">
          <div class="report-title">
            <h1 v-if="titeltext">{{ titeltext }}</h1>
            <h1>{{ merge_department }}</h1>
          </div>
          <div class="title-top-box">
            <div class="title">一、基础信息</div>
            <div class="line"></div>
            <div class="title-box">
              <el-row>
                <el-col :span="8">
                  <span class="marginRight">姓</span><span>名:</span><span class="marginLeft">{{ form.name }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="marginRight">性</span><span>别:</span><span class="marginLeft">{{ form.sex }}</span>
                </el-col>
                <el-col :span="8">
                  <span class="marginRight">年</span><span>龄:</span><span class="marginLeft">{{ form.ages }}岁</span>
                </el-col>
              </el-row>
              <el-row style="padding-top:20px">
                <el-col :span="8">
                  <span class="marginRight">教</span><span>育:</span><span class="marginLeft">{{ form.culture }}</span>
                </el-col>
                <!-- <el-col :span="16">
                  <span>使用单位:</span><span  class="marginLeft">{{ config.unit_name }}</span> 
                </el-col> -->
              </el-row>
            </div>
            <div class="title">二、测验结果</div>
            <div class="line"></div>
            <div style="padding:0px 20px 20px 20px">
              <div style="font-size:20px">
                <el-row style="border-bottom: 2px solid #000;height:40px">
                  <el-col :span="9"> <span>量表</span></el-col>
                  <el-col :span="6"> <span>项目</span></el-col>
                  <el-col :span="3"> <span>计分</span></el-col>
                  <el-col :span="4"> <span>程度</span></el-col>
                </el-row>
                <table>
                  <tbody align="left" v-for="(item, index) of tableInfoList" :key="index">
                    <div style="width:1060px;overflow: hidden;">
                      <tr v-for="(subitem, cindex) of item" :key="cindex" style="height:40px;line-height:40px;">
                        <template>
                          <td style="width:388px;vertical-align:middle;" :rowspan="item.length" v-if="cindex == 0">
                            <div>
                              {{ subitem.measure_title }}
                            </div>
                          </td>
                          <td style="width:272px;text-align:left;line-height:40px;vertical-align:middle;">{{
                            subitem.name
                          }}</td>
                          <td style="width:130px;text-align:left;vertical-align:middle;">{{ subitem.score }}</td>
                          <td style="text-align:left;vertical-align:middle;">{{ subitem.mark }}</td>
                        </template>
                      </tr>
                    </div>
                    <div style="border-bottom: 2px solid #000;"></div>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="title">三、临床综合分析</div>
            <div class="line"></div>
            <div class="footer-box">
              <div v-for="(item, index) in arrNew" :key="index" style="line-height:35px">
                <!-- <p>{{index +1}}、 {{item.comment}}</p> -->
                <p>♦ <span style="font-weight: bold;">{{ item.title }} :</span> {{ item.comment }}</p>
              </div>
            </div>
            <div style="display: flex;">
              <div style="flex:1">
              </div>
              <div style="width:330px;font-size:20px">
                <div style="margin-top:22px">
                  <div>
                    <span>医生签字 ： </span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div style="display:flex;font-size:18px;justify-content: space-between;">
              <div>
                <span>打印时间：{{ report_time }}</span>
              </div>
              <div>
                <span>{{ disclaimer }}</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
import $ from "jquery";
// 前者获取档案信息/get_result_by_id,后者保存编辑内容
import { loadReport, save_meaconclusion } from "@/api/report.js";
import { max } from "moment";
import { updateConfig } from "@/api/comm.js";
import saveAs from "file-saver";
import jszip from "jszip";
import moment from "moment";
import "../../../../public/static/jquery.wordexport";
import { getListConfig } from "@/api/comm.js";

import { log } from "util";

export default {
  inject: ["reload"],
  data() {
    return {
      // 排序
      baseArr: [],
      uid: {},
      // 表数据
      tableInfoList: [],
      tableInfoList2: [],
      itemlist: [],
      form: {},
      measure_title: "",
      arrNew: [],
      report_time: "",
      titeltext: "",
      disclaimer: localStorage.getItem('config')['disclaimer'] || JSON.parse(localStorage.getItem('config'))['disclaimer'],// 免责声明
      merge_department: localStorage.getItem('config')['merge_department'] || JSON.parse(localStorage.getItem('config'))['merge_department'],// 合并报告标题名称
    };
  },
  beforeCreate() { },
  created() {
    getListConfig({ config_type: "system_config,user_config_scope" }).then((res) => {
      if (res.code == 400200) {
        // console.log("全局配置",res.data.unit_name);
        if (res.data.unit_name) {
          this.titeltext = res.data.unit_name;
        }
        if (res.data.merge_department) {
          this.merge_department = res.data.merge_department;
        }
        if (res.data.disclaimer) {
          this.disclaimer = res.data.disclaimer;
        }
      }
    });
    let myDate = new Date();
    this.report_time = myDate.toLocaleString().replaceAll('/', '-');
    localStorage.setItem(`reportTime`, JSON.stringify(this.report_time))

    // 获取全局配置项
    this.config = JSON.parse(localStorage.getItem("config"));
    //  获取用户id.判断单个还是多个id
    let data = {
      id: "",
    };
    if (this.$route.query.id) {
      data.id = this.$route.query.id;
      this.uid.id = this.$route.query.id;
    } else if (this.$route.query.ids) {
      data.id = this.$route.query.ids;
      this.uid.ids = this.$route.query.ids;
      if (this.$route.query.ids.split(",").length == 1) {
      }
    }
    this.loadReport(data);

    // 发起请求渲染报告
  },
  mounted() {
    if (window.location.href.indexOf("#reloaded") == -1) {
      window.location.href = window.location.href + "#reloaded";
      window.location.reload();
    }
    // this.charData();
    this.$nextTick(() => {
      // this.charData();
      // 字体大小设置
      let fontS =
        JSON.parse(localStorage.getItem("config")).report_font_size + "px";
      this.fontSize = fontS;
      // ----------------------
    });
  },
  computed: {
    removeHTMLTag() {
      return function (str) {
        // str = str.replace(/<\/?[^>]*>/g, ""); //去除HTML tag
        // str = str.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
        // str = str.replace(/\n[\s| | ]*\r/g, "\n"); //去除多余空行
        // str = str.replace(/ /gi, ""); //去掉
        str = str.replace(/&nbsp;/gi, "");
        return str;
      };
    },
  },
  filters: {},
  methods: {
    loadReport(data) {
      loadReport(data).then((res) => {
        // 组织总评的数据
        if (res.code == 400200) {
          // 多个
          if (res.data.length > 1) {
            // 用户信息
            this.form = res.data[0].customer;
            // res的数据处理添加时间
            res.data.forEach(item => {
              if (item.base_results.comment) {
                let obj = {
                  comment: item.base_results.comment,
                  title: item.measure_title
                }
                this.arrNew.push(obj)
              }
              if (item.factor_results.length > 0) {
                item.factor_results.forEach(item2 => {
                  item2.evaluating_time = item.customer.evaluating_time.substring(0, 10)
                  item2.measure_title = item.measure_title
                })
              } else if (JSON.stringify(item.base_results) != "{}") {
                // 给每项因子添加时间
                // item.base_results.evaluating_time=item.customer.evaluating_time.substring(0,10);
                item.base_results.measure_title = item.measure_title;

                item.factor_results.push(item.base_results)
              }
            })
            let dataarr = [...res.data]
            dataarr.filter(item => {
              if (item.factor_results) {
                this.tableInfoList.push(item.factor_results)
              }
            })
            console.log("arr", this.tableInfoList);
          }
        }

      });
    },
    //分组
    groupBy(array, f) {
      var groups = {};
      array.forEach(function (o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
        return groups[group];
      });
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
  },
  filters: {},
  watch: {},
};
</script>
<style lang="less">
.tc-report {
  position: relative;

  // 页面功能
  .tool {
    position: absolute;
    top: -50px;
    right: 0;
    overflow: hidden;

    ul {
      li {
        cursor: pointer;
        list-style: none;
        float: left;
        margin-left: 10px;

        img {
          width: 100px;
        }
      }
    }
  }

  //   顶部工具栏
  .tc-report-header {
    padding: 0 10px;
    height: 50px;
    background: rgb(217, 242, 247);
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-right: 50px;
    border-radius: 5px;
    min-width: 860px;

  }

  // 报告
  .tc-report-main {
    width: 100%;

    // 报告内容部分
    .content {
      max-width: 1200px;
      margin: auto;
      background: white;
      padding: 15px 50px;
      box-sizing: border-box;

      // 报表头部
      .report-title {
        font-size: 32px;
        text-align: center;
      }

    }
  }
}


@media screen and (max-width: 768px) {
  .tc-report {
    zoom: 0.55;
    overflow: auto;
  }
}
</style>

<style scoped lang="less">
.theadtr {
  th {
    border-bottom: 2px solid #000;
  }
}

.title-top-box {
  font-size: 20px;
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;

  .marginRight {
    margin-right: 44px;
  }

  .marginLeft {
    margin-left: 20px;
  }

  .title {
    margin-left: 20px;
    font-weight: bold;
    margin-top: 40px;

  }

  .line {
    height: 4px;
    background-color: #000;
    margin: 20px 0px;
  }

  .title-box {
    box-sizing: border-box;
    margin: 30px;
  }

  .footer-box {
    box-sizing: border-box;
    padding: 20px 20px 40px 20px;
  }

}

table {
  table-layout: fixed;
}

td {
  word-break: break-all;
  word-wrap: break-word;
}
</style>